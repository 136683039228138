import React from 'react'
import TrackVisibility from 'react-on-screen'
import Layout from '../components/Layout'
import CoursesHeader from '../components/Header/CoursesHeader'
import PromotionBannerAnimation from '../components/PromotionBannerAnimation'

import ProductsCards from '../components/ProductsCards/ProductsCards'

import imgHeader from '../buildAssets/logos/general/generice-background-lg.png'
import imgHeaderMob from '../buildAssets/logos/general/generice-background-sm.png'
import icon1 from '../buildAssets/logos/products/up-and-down-arrows.svg'
import icon2 from '../buildAssets/logos/products/circle.svg'
import icon3 from '../buildAssets/logos/products/bite-sized-training.svg'
import icon4 from '../buildAssets/logos/products/create-experiences.svg'
import icon5 from '../buildAssets/logos/products/all-devices.svg'
import icon6 from '../buildAssets/logos/products/power-on.svg'

import cardIcon1 from '../buildAssets/logos/compliance/logo-compliance.png'
import cardIcon2 from '../buildAssets/logos/culture/logo-culture.png'
import cardIcon3 from '../buildAssets/logos/phishing/logo-phishing.png'
import cardIcon4 from '../buildAssets/logos/bespoke/logo-bespoke.png'
import { graphql } from 'gatsby'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'





const Products = ({ data }) => {
  const { wpPage } = data
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)

  return (
    <Layout>
      <CoursesHeader
        smallText="Cybersecurity awareness training products that work"
        heading=" Our products"
        subheading="Build your cybersecurity culture with solutions designed for every organisation."
        imageDesktop={imgHeader}
        imageMob={imgHeaderMob}
        breadcrumb={'Products'}
        breadcrumbURL={'/products'}
      />

      {renderedBlocks.map((v) => v)}

    
    </Layout>
  )
}

export const query = graphql`
  query ProductsQuery {
    allWpPost {
      edges {
        node {
          id
          categories {
            nodes {
              id
              name
              slug
            }
          }

          title
          slug
        }
      }
    }
    wpPage(title: { eq: "Products" }) {
      id
      lazy_data
      title
    }
  }
`

export default Products
