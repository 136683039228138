import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb'
import MainGrid from '../styling/MainGrid'
// import imgHeader from '../../images/courses-header.jpg'
// import imgHeaderMob from '../../images/courses-header-m.jpg'

import { graphql,useStaticQuery } from 'gatsby'

interface CoursesHeaderProps {
  smallText: string
  heading: string
  subheading: string
  imageDesktop: string
  imageMob: string
  breadcrumb: string
  breadcrumbURL: string
}

const CoursesHeader: FunctionComponent<CoursesHeaderProps> = ({
  smallText,
  heading,
  subheading,
  imageDesktop,
  imageMob,
  breadcrumb,
  breadcrumbURL,
}) => {

  const imagesCollection = useStaticQuery(graphql`

    query img8 {
      imgHeader: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "courses-header.jpg"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 660,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      imgHeaderMob: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "courses-header-m.jpg"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 660,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const imgHeader = imagesCollection.imgHeader.edges[0].node.childImageSharp.fluid.src
  const imgHeaderMob = imagesCollection.imgHeaderMob.edges[0].node.childImageSharp.fluid.src  

  const data = {
    smallText: 'Cybersecurity training that works',
    heading: 'Course collection',
    subheading:
      'Build a culture of cyber learning that reduces human risks, egestas quis lobortis id, efficitur et metus donec ut enim volutpat.',
    imageDesktop: imgHeader,
    imageMob: imgHeaderMob,
  }
  
  const breadcrumbData = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: breadcrumb,
      url: breadcrumbURL,
    },
  ]
  return (
    <Container imageMob={imageMob} imageDesktop={imageDesktop}>
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} color={'white'} />
      </MainGrid>
      <MainGrid>
        <TextContainer>
          {smallText && <SmallText className="small-p">{smallText}</SmallText>}

          {heading && <Heading>{heading}</Heading>}

          {subheading && <Body>{subheading}</Body>}
        </TextContainer>
      </MainGrid>
    </Container>
  )
}

export default CoursesHeader

interface ContainerProps {
  imageDesktop: string
  imageMob: string
}

const Container = styled.div<ContainerProps>`
  background-image: url(${(props) => props.imageMob});

  background-repeat: no-repeat;
  background-size: auto 100% ;
  background-position: center;
  @media (min-width: 1024px) {
    background-image: url(${(props) => props.imageDesktop});
    background-repeat: no-repeat;
  background-size: cover;

  }
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 126px 0 65px;
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 0 84px;
    max-width: 56rem;
  }
`

const SmallText = styled.p`
  margin-bottom: 15px;
  font-weight: 300;

  color: white;
`

const Heading = styled.h1`
  margin-bottom: 16px;

  color: white;

  @media (min-width: 1024px) {
    margin-bottom: 11px;
  }
`

const Body = styled.p`
  color: white;
`
